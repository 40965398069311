export default function sortableObjectArrayByIds(ids, objArray) {
  if (!ids.length) return objArray
  ids = ids.map((id) => Number(id))

  const sortable = ids.map((id) =>
    objArray.find((obj) => Number(obj.id) === id)
  )
  const rest = objArray.filter((obj) => !ids.includes(Number(obj.id)))

  return [...sortable, ...rest].filter((mb) => !!mb)
}
