<template>
  <div
    :class="[
      'shelf',
      {
        'shelf--crossell': isCrossell,
        'shelf--no-crossell': !isCrossell,
        'shelf--loading': loading,
      },
    ]"
  >
    <div :class="['container', { shelf__container: !isCrossell }]">
      <div v-if="loading" class="shelf__loading">
        <three-dots-loading />
      </div>
      <transition name="fade">
        <div v-if="!loading && isPresent(products)">
          <h2 class="shelf__title">
            {{ label }}
            <span v-if="timer" class="shelf__end-in__label">
              <span class="promotion_end_in">{{ timer }}</span>
            </span>
          </h2>
          <small v-if="isCrossell" class="shelf__subtitle">
            {{ $t('shelf.add_to_cart_crossell') }}
          </small>
          <div
            class="mobile-only shelf__wrapper swiper-container mobile-swiper shelf__products"
          >
            <product
              v-for="(product, idx) in products"
              :key="idx"
              :change-device-image-slug="changeDeviceImageSlug"
              :active-bf-bag-promo="true"
              :show-new-badge="
                newProducts.includes(product && product.id ? product.id : 0)
              "
              :case-device-id="caseDeviceId"
              :fixed-price="fixedPrice"
              :is-cart-crossell="isCrossell"
              :product="product"
              :catalog-has-badge="
                isPresent(product && product.badge ? product.badge : [])
              "
              :show-promotion-event="false"
              :class="['max-width', 'shelf__product', 'list-product--boxed']"
              @click.native="sendClickShelfProductsToGTM"
            />
          </div>
          <div class="desktop-only shelf__wrapper swiper-container">
            <!-- these buttons with div, are to be a div and not button, because button have a bug here -->
            <div
              v-if="products.length > currentPerView || isCrossell"
              :class="['shelf__prev-btn', { disabled: activeItem === 0 }]"
              @click="chooseItem('<')"
            >
              <arrowLeft class="shelf__arrow" />
            </div>
            <div
              v-if="products.length > currentPerView || isCrossell"
              :class="[
                'shelf__next-btn',
                {
                  disabled: activeItem + currentPerView === products.length,
                },
              ]"
              @click="chooseItem('>')"
            >
              <arrowRight class="shelf__arrow" />
            </div>
            <vue-glide
              v-model="activeItem"
              :bound="true"
              :per-view="currentPerView"
              :breakpoints="breakPoints"
              :active="activeItem"
              direction="ltr"
            >
              <vue-glide-slide v-for="(product, idx) in products" :key="idx">
                <product
                  :key="idx"
                  :active-bf-bag-promo="true"
                  :change-device-image-slug="changeDeviceImageSlug"
                  :show-new-badge="
                    newProducts.includes(product && product.id ? product.id : 0)
                  "
                  :product="product"
                  :case-device-id="caseDeviceId"
                  :fixed-price="fixedPrice"
                  :catalog-has-badge="
                    isPresent(product && product.badge ? product.badge : [])
                  "
                  :show-promotion-event="false"
                  :is-cart-crossell="isCrossell"
                  :class="[
                    'max-width',
                    'shelf__product',
                    'list-product--boxed',
                  ]"
                  :type="'shelfProduct'"
                  @click.native="sendClickShelfProductsToGTM"
                />
              </vue-glide-slide>
            </vue-glide>
          </div>

          <div class="shelf__cta">
            <nuxt-link
              v-if="seeMoreLink"
              class="shelf__see-more"
              :to="seeMoreLink"
            >
              <span class="shelf__see-more__label">
                {{ $t('shelf.see_more') }}
                <span class="shelf__see-more-arrow">&rarr;</span>
              </span>
            </nuxt-link>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Glide, GlideSlide } from '@gedhean/vue-glide-js'
import '@gedhean/vue-glide-js/dist/vue-glide.css'
import { R_SHELFS } from '@/config'
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import isPresent from '~/utils/isPresent'
import timer from '~/mixins/timer'
import appendUrlParam from '~/utils/appendUrlParam'
import Product from '~/components/product/Product.vue'
import ArrowLeft from '~/components/icons/arrow-left/ArrowLeft'
import ArrowRight from '~/components/icons/arrow-right/ArrowRight'
import adaptUrl from '~/commun/utils/adaptUrl'
import ThreeDotsLoading from '~/components/loadings/three-dots'
import renderRichRelevance from '~/mixins/render-rich-relevance'
import createIntersectionHandler from '~/commun/utils/createIntersectionHandler'
import sortableObjectArrayByIds from '~/utils/sortableObjectArrayByIds'
import sortableObjectArrayBySku from '~/utils/sortableObjectArrayBySku'

const DEFAULT_PER_VIEW = 5

export default {
  name: 'Shelf',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    Product,
    ArrowLeft,
    ArrowRight,
    ThreeDotsLoading,
  },
  mixins: [timer, renderRichRelevance, pushDataLayer],
  props: {
    changeDeviceImageSlug: {
      type: Array,
      default: null,
    },
    shelfProducts: {
      type: Object,
      required: true,
    },
    caseDeviceId: {
      type: [Number, String],
      default: null,
    },
    fixedPrice: {
      type: [Number, String],
      default: null,
    },
    lineItems: {
      type: Array,
      default: null,
    },
    useCache: {
      type: Boolean,
      default: false,
    },
    firstShelf: {
      type: Boolean,
      default: false,
    },
    forceBreakPoints: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    if (!this.lazyFetch) this.shelf = await this.fetchShelf()
    this.translatedToOtherLangs()
    if (this.$locale !== 'de' && this.shelf && !this.timerId) this.startTimer()
  },
  data() {
    return {
      shelf: null,
      activeItem: 0,
      products: [],
      label: '',
      endDate: '',
      seeMoreLink: '',
      currentPerView: DEFAULT_PER_VIEW,
      loading: true,
      newProducts: [],
    }
  },
  watch: {
    forceBreakPoints(fbp) {
      if (fbp && this.$device.isDesktop) {
        setTimeout(() => {
          const event = new Event('resize')
          window.dispatchEvent(event)
        }, 100)
      }
    },
    async '$store.state.globalDevice'(globalDevice) {
      if (globalDevice) {
        if (this.isRichRelevance) {
          await this.fetchRichRelevance(this.shelf)
        } else {
          await this.$fetch()
        }
      }
    },
  },
  computed: {
    ...mapState(['storeCode', 'locale', 'currency']),
    breakPoints() {
      return {
        5000: {
          perView: this.isCrossell ? 3 : 5,
        },
        800: {
          perView: this.isCrossell ? 3 : 4,
        },
        600: {
          perView: 3,
        },
        450: {
          perView: this.isCrossell ? 1.5 : 2.4,
        },
      }
    },
    isCrossell() {
      return this.shelfProducts.type === 'crossell'
    },
    isRichRelevance() {
      return [this.shelf?.type, this.shelfProducts?.type].includes(
        'rich_relevance'
      )
    },
    lazyFetch() {
      return !this.useCache || this.isRichRelevance
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.breakPointsHandler)
  },
  mounted() {
    window.addEventListener('resize', this.breakPointsHandler)
    this.breakPointsHandler()

    if (this.shelfProducts.type === 'crossell') {
      this.fetchCrossell()
      return
    }

    if (this.lazyFetch) this.lazyFetchShelf()
    this.translatedToOtherLangs()
    if (this.$locale !== 'de' && this.shelf) this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timerId)
    this.timerId = null
  },
  methods: {
    sendClickShelfProductsToGTM() {
      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'navigation',
        eventAction: 'click_shelf_products',
      })
    },
    isPresent,
    crossellIdentifier(sku) {
      if (sku.match('garota-mistica|mystic-girl'))
        return 'garota-mistica,garota-mistica-oki,garota-mistica-tech'
      if (sku.match('kansas-city-chiefs')) return 'kansas-city-chiefs'
    },
    async fetchCrossell() {
      this.loading = true
      const productId = this.shelfProducts.productId

      const prodSku = this.$store.state.product.sku

      const params = {
        ...this.$store.getters.storeInfo,
        spree_product_id: productId,
        identifier: this.crossellIdentifier(prodSku),
      }

      const { products } = await this.$axios.$get('/products/cross_sell', {
        params,
      })

      this.products = products

      this.label = this.$t('product_form.crossell')

      this.products.forEach((prod) => {
        this.fetchCrossellProductImage(prod)
      })

      this.loading = false
    },
    async fetchCrossellProductImage(product) {
      try {
        const params = {
          ...this.$store.getters.storeInfo,
          product: {
            identifier: product.identifier,
            material: product.material,
            slug: product.slug,
          },
          base_customization_url: document.querySelector(
            '.engine-input #input_engine_value'
          ).value,
        }

        const {
          mockup_url: mockupUrl,
          customization_url: customizationUrl,
        } = await this.$axios.$post(
          'https://catalog-api.gocase.com.br/api/v1/cross_sell',
          params,
          { withCredentials: false }
        )

        product.image = mockupUrl
        product.mockup_url = mockupUrl
        product.customization_url = customizationUrl

        this.$set(product, 'image', product.image)
        this.$set(product, 'mockup_url', product.mockup_url)
        this.$set(product, 'customization_url', product.customization_url)
      } catch (err) {}
    },
    fixGlobalDeviceToDuoAndAirPods(products, caseDevice) {
      // TODO: DUO CASE GAMBI (DO IN BACKEND WHEN ISA BACK)
      products = products.map((prod) => {
        if (prod.sku.startsWith('duo-')) {
          prod.image = prod.image.replace(caseDevice, 'caseduocoral-iphone11')
        } else if (prod.product_type_code === 'airpodcase') {
          prod.image = prod.image.replace(caseDevice, 'transparent-airpod')
        }
        return prod
      })
      return products
    },
    translatedToOtherLangs() {
      if (
        this.$locale !== 'de' &&
        this.$locale !== 'fr' &&
        this.$locale !== 'it'
      )
        return

      setTimeout(() => {
        this.languageSelector(this.$locale)
      }, 1000)
    },
    languageSelector(language) {
      try {
        const shelfTitleEl = this.$el?.querySelector('.shelf__title')
        if (shelfTitleEl) {
          const shelfTitle = shelfTitleEl.textContent
          if (shelfTitle.match('People who bought')) {
            const regex = /(People who bought )(.*)( also bought)/
            const productName = regex.exec(shelfTitle)?.[2]
            shelfTitleEl.textContent =
              R_SHELFS[language]?.['People who bought']?.[0] +
              productName +
              R_SHELFS[language]?.['People who bought']?.[1]
          } else if (shelfTitle.match('People viewing')) {
            const regex = /(People viewing )(.*)( also like)/
            const productName = regex.exec(shelfTitle)?.[2]
            shelfTitleEl.textContent =
              R_SHELFS[language]?.['People viewing']?.[0] +
              productName +
              R_SHELFS[language]?.['People viewing']?.[1]
          } else if (shelfTitle.match('Similar products')) {
            shelfTitleEl.textContent = R_SHELFS[language]?.['Similar products']
          } else if (shelfTitle.match('Best Sellers and Flash Sales')) {
            shelfTitleEl.textContent =
              R_SHELFS[language]?.['Best Sellers and Flash Sales']
          } else if (shelfTitle.match('You previously viewed')) {
            shelfTitleEl.textContent =
              R_SHELFS[language]?.['You previously viewed']
          } else if (shelfTitle.match('People with similar')) {
            shelfTitleEl.textContent =
              R_SHELFS[language]?.['People with similar']
          } else if (shelfTitle.match('products in your favorite product')) {
            shelfTitleEl.textContent =
              R_SHELFS[language]?.['products in your favorite product']
          } else if (shelfTitle.match('People who viewed')) {
            const regex = /(People who viewed )(.*)( then bought)/
            const productName = regex.exec(shelfTitle)?.[2]
            shelfTitleEl.textContent =
              R_SHELFS[language]?.['People who viewed']?.[0] +
              productName +
              R_SHELFS[language]?.['People who viewed']?.[1]
          } else if (
            shelfTitle.match('similar to the items in your shopping')
          ) {
            shelfTitleEl.textContent =
              R_SHELFS[language]?.['similar to the items in your shopping']
          } else if (
            shelfTitle.match('These are similar to the items in your trolley')
          ) {
            shelfTitleEl.textContent =
              R_SHELFS[language]?.[
                'These are similar to the items in your trolley'
              ]
          }
        }
      } catch (err) {
        this.$sentry.captureException(err)
      }
    },
    sortableProducts() {
      if (this.$route.query.shelf_product_ids) {
        const ids = this.$route.query.shelf_product_ids.split(',')

        if (this.products.length) {
          this.products = sortableObjectArrayByIds(ids, this.products)
        }
      } else if (this.$route.query.sku) {
        const skus = this.$route.query.sku.split(',')
        if (this.products.length) {
          this.products = sortableObjectArrayBySku(skus, this.products)
        }
      }
    },
    breakPointsHandler(e) {
      const windowWidth = window.innerWidth
      if (windowWidth < 450) return

      for (const point in this.breakPoints) {
        if (windowWidth < point) {
          const perView = this.breakPoints[point].perView
          if (perView < this.currentPerView) {
            this.currentPerView = perView
          }
        } else {
          this.currentPerView = DEFAULT_PER_VIEW
        }
      }
    },
    chooseItem(direction) {
      const directions = { right: '>', left: '<' }
      const gotoItem = this.activeItem + this.currentPerView
      const restItems = this.products.length - gotoItem

      if (direction === directions.right) {
        if (restItems === 0) this.activeItem = restItems
        else if (restItems < this.currentPerView)
          this.activeItem = this.activeItem + restItems
        else this.activeItem = gotoItem
      } else if (direction === directions.left) {
        if (this.activeItem === 0) this.activeItem = restItems
        else if (this.currentPerView > this.activeItem) this.activeItem = 0
        else this.activeItem = this.activeItem - this.currentPerView
      } else {
        throw new Error(
          `Direction not available, try use: ${directions.right} or ${directions.left}`
        )
      }
    },
    async fetchRichRelevance(shelf = this.shelfProducts) {
      try {
        this.loading = true
        let productIds = []
        let label = ''

        // eslint-disable-next-line camelcase
        if (shelf?.product_ids?.length) {
          productIds = shelf.product_ids
          label = shelf.label
        } else if (shelf) {
          let result
          try {
            ;[result] = await this.retry(
              () =>
                this.loadRichRelevanceShelf(
                  shelf.placement_name,
                  this.lineItems,
                  null,
                  shelf.richProducts,
                  shelf.taxon
                ),
              3
            )
          } catch (err) {
            throw new Error('loadRichRelevanceShelf -> ' + err.message)
          }

          productIds = (result?.items || []).map((it) => parseInt(it?.id))
          label = result?.message
        }

        await this.fetchProducts(productIds, label)
      } catch (err) {
        this.products = []
      } finally {
        this.loading = false
      }
    },
    async fetchProducts(productIds, label) {
      const prodIds = productIds.join(',')
      const params = {
        ...this.$store.getters.storeInfo,
        product_ids: prodIds,
      }

      const globalDevice = this.$store.state.globalDevice
      if (globalDevice) {
        params.case_device = `${globalDevice.case_type_code}-${globalDevice.device_code}`
      }

      const { products, new_products: newProducts } = await this.$axios.$get(
        '/products/search',
        {
          params,
        }
      )

      this.newProducts = newProducts || []

      this.products = productIds
        .map((id) => products.find((prod) => prod?.id === Number(id)))
        ?.filter((prod) => !!prod)

      if (globalDevice) {
        this.products = this.fixGlobalDeviceToDuoAndAirPods(
          this.products,
          params.case_device
        )
      }

      this.label = label

      if (this.firstShelf) this.sortableProducts()
    },
    async fetchShelf() {
      this.loading = true

      try {
        const {
          id: showcaseId,
          end_date: endDate,
          label,
          see_more: seeMore,
        } = this.shelfProducts

        if (!showcaseId) return

        if (!this.label) this.label = label
        this.seeMoreLink = adaptUrl(seeMore)
        this.endDate = endDate

        const params = {
          ...this.$store.getters.storeInfo,
        }

        const globalDevice = this.$store.state?.globalDevice

        const caseTypeParam =
          // eslint-disable-next-line camelcase
          this.$route?.query?.case_type ||
          // eslint-disable-next-line camelcase
          globalDevice?.case_type_code
        const deviceParam =
          this.$route?.query?.device ||
          // eslint-disable-next-line camelcase
          globalDevice?.device_code

        if (caseTypeParam) params.case_type = caseTypeParam
        if (deviceParam) params.device = deviceParam

        if (this.$store.state.skipStoreValidation) {
          params.skip_product_store = true
        }

        const endpointUrl = appendUrlParam('/shelf/' + showcaseId, params)

        const shelf = await this.$axios.$get(endpointUrl, {
          cache: {
            readOnError: true,
            ignoreCache: !this.useCache,
          },
        })
        // eslint-disable-next-line camelcase
        this.endDate = shelf?.end_date

        this.products = shelf?.products?.filter((prod) => !!prod) || []

        // eslint-disable-next-line camelcase
        this.caseDeviceId = shelf?.case_device_id

        if (globalDevice) {
          this.products = this.fixGlobalDeviceToDuoAndAirPods(
            this.products,
            `${globalDevice.case_type_code}-${globalDevice.device_code}`
          )
        }

        if (this.firstShelf) this.sortableProducts()

        // eslint-disable-next-line camelcase
        this.newProducts = shelf?.new_products || []
        this.loading = false

        return shelf
      } catch (err) {
        this.loading = false
        this.$displayTopMessage(this.$t('action_error'), {
          type: 'error',
        })

        this.$sentry.captureException(err.response || err)

        return null
      }
    },
    lazyFetchShelf() {
      const onIntersection = async () => {
        this.shelf = await this.fetchShelf()
        // eslint-disable-next-line camelcase
        this.endDate = this.shelf?.end_date

        // eslint-disable-next-line camelcase
        this.newProducts = this.shelf?.new_products || []

        if (this.isRichRelevance) {
          this.loading = true
          await this.fetchRichRelevance(this.shelf)
          this.translatedToOtherLangs()

          this.loading = false
        }
      }

      const init = createIntersectionHandler(this.$el, onIntersection, {
        once: true,
      })

      init()
    },
  },
  fetchOnServer() {
    return this.useCache
  },
}
</script>

<style lang="scss">
.shelf {
  background-color: #fcfdf7;
  position: relative;
  min-height: 399px;

  &--crossell {
    min-height: auto;
    background: transparent;

    .shelf__title {
      margin-top: 0 !important;
      padding: 0 !important;
      margin-left: 0 !important;
    }
    .shelf__wrapper {
      padding: 0 !important;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 0 !important;
    }
    .container {
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
    }
  }

  &.shelf--grey {
    background-color: #f6f6fa;

    .shelf__prev-btn,
    .shelf__next-btn {
      background-color: darken(#f6f6fa, 2%);
    }
  }
  &.shelf--light-teal {
    background-color: #f5fbfb;

    .shelf__prev-btn,
    .shelf__next-btn {
      background-color: darken(#f5fbfb, 3%);
    }
  }

  &.shelf--teal {
    background-color: #94d6da;

    .shelf__title {
      color: white;
    }

    .shelf__prev-btn,
    .shelf__next-btn {
      background-color: darken(#94d6da, 6%);
    }
    .shelf__prev-btn .shelf__arrow,
    .shelf__next-btn .shelf__arrow {
      fill: darken(#5ca4a8, 15%);
    }

    .shelf__see-more {
      background-color: darken(#5ca4a8, 5%);

      &:hover {
        background-color: darken(#5ca4a8, 15%);
      }
    }
  }

  &.shelf--white {
    background-color: white;
  }

  & + .shelf {
    border-top: 1px solid $v4_color_line_lighter;
  }

  .shelf__loading {
    min-height: 399px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shelf__container {
    padding: 0;
  }

  @media (max-width: $v4_desktop) {
    .shelf__container > div {
      padding-top: 12px;
    }
  }

  .shelf__wrapper {
    position: relative;
    margin-bottom: 42px;
  }

  .shelf__title {
    margin-top: 30px;
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    color: $v4_color_text;
  }

  .shelf__subtitle {
    margin-bottom: 10px;
    margin-top: -15px;
    display: block;
  }

  .shelf__cta {
    margin-bottom: -20px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 1;
  }

  .shelf__see-more {
    padding: 12px 18px 12px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    background-color: $v4_color_primary;
    color: white;
    min-width: 200px;

    .shelf__see-more-arrow {
      display: inline-block;
      transition: all 200ms ease-in-out;
    }

    &:hover {
      background-color: darken($v4_color_primary, 5%);

      .shelf__see-more-arrow {
        transform: translateX(5px);
      }
    }
  }

  .glide {
    box-sizing: border-box !important;
  }

  .glide__slides {
    padding-left: 15px;
    min-height: 280px;
    box-sizing: border-box !important;
  }

  .shelf__prev-btn,
  .shelf__next-btn {
    color: white;
    background-color: #fcf6f3;
    border-radius: 50%;
    top: 50%;
    text-align: center;
    position: absolute;
    cursor: pointer;
    outline: 0;
    border: 0;
    width: 54px;
    height: 54px;
    line-height: 60px;
    margin-top: -29px;
    text-align: center;
    z-index: 10;

    .shelf__arrow {
      width: 23px;
      fill: $v4_color_primary;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    &.disabled {
      display: none;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  .shelf__prev-btn {
    left: 15px;
    right: auto;

    &:hover .shelf__arrow {
      transform: translateX(-4px);
    }
  }
  .shelf__next-btn {
    right: 15px;
    left: auto;

    &:hover .shelf__arrow {
      transform: translateX(4px);
    }
  }

  @media (min-width: 1105px) {
    .shelf__title {
      margin-top: 50px;
      font-size: 27px;
      text-align: center;
    }
    .shelf__subtitle {
      text-align: center;
      display: block;
      margin-bottom: 15px;
    }
    .shelf__wrapper {
      margin-bottom: 70px;
    }

    .shelf__container {
      padding: initial;
    }
    .glide__slides {
      padding-left: 0;
      box-sizing: initial;
    }
  }
}

@media (min-width: 1200px) {
  html .shelf {
    .glide__track {
      max-height: 336px;
      overflow-y: hidden;
    }
  }
}

@media (min-width: 1304px) {
  html .shelf {
    &--crossell {
      .shelf__wrapper {
        margin: 0px !important;
      }
    }

    &--no-crossell {
      min-height: 450px;

      .shelf__loading {
        min-height: 450px;
      }

      .shelf__prev-btn,
      .shelf__next-btn {
        z-index: 0;
        width: 180px;
        height: 180px;
        line-height: 186px;
        margin-top: -90px;
      }
      .shelf__prev-btn {
        text-align: left;
        padding-left: 21px;
        left: -58px;
      }
      .shelf__next-btn {
        text-align: right;
        padding-right: 21px;
        right: -58px;
      }
    }
  }
}

.mobile-swiper {
  overflow: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
  }

  overflow: -moz-scrollbars-none;

  .list-product {
    // max-height: 257px;
    display: inline-block;
    max-width: 216px;
    width: 100%;
    margin: 0px 5px;

    img.product-image__thumbnail {
      width: 186px;
      height: 186px;
    }

    &:first-child {
      margin-left: 15px;
    }

    &:last-child {
      margin-right: 15px;
    }
  }
}
</style>
