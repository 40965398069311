var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'shelf',
    {
      'shelf--crossell': _vm.isCrossell,
      'shelf--no-crossell': !_vm.isCrossell,
      'shelf--loading': _vm.loading,
    } ]},[_c('div',{class:['container', { shelf__container: !_vm.isCrossell }]},[(_vm.loading)?_c('div',{staticClass:"shelf__loading"},[_c('three-dots-loading')],1):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading && _vm.isPresent(_vm.products))?_c('div',[_c('h2',{staticClass:"shelf__title"},[_vm._v("\n          "+_vm._s(_vm.label)+"\n          "),(_vm.timer)?_c('span',{staticClass:"shelf__end-in__label"},[_c('span',{staticClass:"promotion_end_in"},[_vm._v(_vm._s(_vm.timer))])]):_vm._e()]),_vm._v(" "),(_vm.isCrossell)?_c('small',{staticClass:"shelf__subtitle"},[_vm._v("\n          "+_vm._s(_vm.$t('shelf.add_to_cart_crossell'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mobile-only shelf__wrapper swiper-container mobile-swiper shelf__products"},_vm._l((_vm.products),function(product,idx){return _c('product',{key:idx,class:['max-width', 'shelf__product', 'list-product--boxed'],attrs:{"change-device-image-slug":_vm.changeDeviceImageSlug,"active-bf-bag-promo":true,"show-new-badge":_vm.newProducts.includes(product && product.id ? product.id : 0),"case-device-id":_vm.caseDeviceId,"fixed-price":_vm.fixedPrice,"is-cart-crossell":_vm.isCrossell,"product":product,"catalog-has-badge":_vm.isPresent(product && product.badge ? product.badge : []),"show-promotion-event":false},nativeOn:{"click":function($event){return _vm.sendClickShelfProductsToGTM.apply(null, arguments)}}})}),1),_vm._v(" "),_c('div',{staticClass:"desktop-only shelf__wrapper swiper-container"},[(_vm.products.length > _vm.currentPerView || _vm.isCrossell)?_c('div',{class:['shelf__prev-btn', { disabled: _vm.activeItem === 0 }],on:{"click":function($event){return _vm.chooseItem('<')}}},[_c('arrowLeft',{staticClass:"shelf__arrow"})],1):_vm._e(),_vm._v(" "),(_vm.products.length > _vm.currentPerView || _vm.isCrossell)?_c('div',{class:[
              'shelf__next-btn',
              {
                disabled: _vm.activeItem + _vm.currentPerView === _vm.products.length,
              } ],on:{"click":function($event){return _vm.chooseItem('>')}}},[_c('arrowRight',{staticClass:"shelf__arrow"})],1):_vm._e(),_vm._v(" "),_c('vue-glide',{attrs:{"bound":true,"per-view":_vm.currentPerView,"breakpoints":_vm.breakPoints,"active":_vm.activeItem,"direction":"ltr"},model:{value:(_vm.activeItem),callback:function ($$v) {_vm.activeItem=$$v},expression:"activeItem"}},_vm._l((_vm.products),function(product,idx){return _c('vue-glide-slide',{key:idx},[_c('product',{key:idx,class:[
                  'max-width',
                  'shelf__product',
                  'list-product--boxed' ],attrs:{"active-bf-bag-promo":true,"change-device-image-slug":_vm.changeDeviceImageSlug,"show-new-badge":_vm.newProducts.includes(product && product.id ? product.id : 0),"product":product,"case-device-id":_vm.caseDeviceId,"fixed-price":_vm.fixedPrice,"catalog-has-badge":_vm.isPresent(product && product.badge ? product.badge : []),"show-promotion-event":false,"is-cart-crossell":_vm.isCrossell,"type":'shelfProduct'},nativeOn:{"click":function($event){return _vm.sendClickShelfProductsToGTM.apply(null, arguments)}}})],1)}),1)],1),_vm._v(" "),_c('div',{staticClass:"shelf__cta"},[(_vm.seeMoreLink)?_c('nuxt-link',{staticClass:"shelf__see-more",attrs:{"to":_vm.seeMoreLink}},[_c('span',{staticClass:"shelf__see-more__label"},[_vm._v("\n              "+_vm._s(_vm.$t('shelf.see_more'))+"\n              "),_c('span',{staticClass:"shelf__see-more-arrow"},[_vm._v("→")])])]):_vm._e()],1)]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }