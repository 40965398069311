export default {
  methods: {
    async retry(callback, retryTimes = 3) {
      let tries = 0
      let result

      while (tries < retryTimes && !result) {
        result = await new Promise((resolve, reject) => {
          callback()
            .then((res) => {
              resolve(res)
            })
            .catch((err) => reject(err))
        })

        if (!result || !result?.length) {
          tries++
        }

        if (tries > 2 && (!result || !result?.length)) {
          throw new Error('Trier result: ' + result + ', tries: ' + tries)
        }
      }

      return result
    },
  },
}
