/* eslint-disable new-cap */
/* eslint-disable camelcase */

import loadScript from '~/utils/loadScript'
import retrier from '~/mixins/retrier'
let R3_COMMON = null

export default {
  mixins: [retrier],
  methods: {
    async loadRichRelevanceShelf(
      placementName,
      lineItems = [],
      product,
      products = [],
      taxon
    ) {
      const firstPlacementName =
        typeof placementName === 'string' ? placementName : placementName[0]

      try {
        return await this.richInitializer(() => {
          this.setPlacement(placementName)
          this.setRegion()
          this.setCategoryId(product, taxon)
          this.renderPageShelfAndTrack(
            firstPlacementName,
            products,
            lineItems,
            product,
            taxon
          )
        }, true)
      } catch (err) {
        throw new Error(err)
      }
    },
    async initRichRelevanceShelves(placementNames) {
      const richRelevances = await this.retry(
        () =>
          this.loadRichRelevanceShelf(
            placementNames,
            this.lineItems,
            this.product
          ),
        3
      )

      this.richRelevances = richRelevances.map((rr) => {
        return {
          product_ids: (rr?.items || []).map((it) => parseInt(it?.id)),
          label: rr?.message,
        }
      })
    },
    async richInitializer(callback, isToShelf = true) {
      if (typeof window.RR === 'undefined') await this.loadRichScript()

      return await new Promise((resolve, reject) => {
        try {
          if (isToShelf) {
            if (window.RR)
              window.RR.jsonCallback = function () {
                resolve(window.RR.data.JSON.placements)
              }
          }

          if (typeof window?.r3_common === 'function') {
            R3_COMMON = new window.r3_common()

            R3_COMMON.setApiKey(
              this.$store.state.storeCode === 'global'
                ? '1bdc0c25640d4f57'
                : '50c1390540383a36'
            )

            R3_COMMON.setBaseUrl('https://recs.richrelevance.com/rrserver/')

            R3_COMMON.setClickthruServer(
              window.location.protocol + '//' + window.location.host
            )

            R3_COMMON.setSessionId(
              this.$store.state?.user?.rich_relevance_session_id
            )

            R3_COMMON.setUserId(this.$store?.state?.user?.id)

            callback()
          }

          // force call window.onload for rr. Need for lazy load.
          typeof window.rr_flush_onload === 'function' &&
            window.rr_flush_onload()

          typeof window.rr_flush_onload === 'function' && window.r3()
        } catch (err) {
          reject(err)
        }
      })
    },
    setPlacement(placementName) {
      if (typeof placementName === 'object') {
        placementName.forEach((pn) => {
          R3_COMMON.addPlacementType(pn)
        })
      } else {
        R3_COMMON.addPlacementType(placementName)
      }
    },
    setRegion() {
      if (this.$store.state.storeCode === 'global') {
        R3_COMMON.setRegionId(this.$store.state.currency)
      }
    },
    setCategoryId(product, taxon) {
      if (product?.taxon_id || taxon?.id) {
        R3_COMMON.addCategoryHintId(product?.taxon_id || taxon?.id)
      }
    },
    renderPageShelfAndTrack(
      firstPlacementName,
      products,
      lineItems,
      product,
      taxon
    ) {
      if (firstPlacementName.match('home_page')) {
        // const R3_HOME = new window.r3_home()
      } else if (
        firstPlacementName.match('category_page') &&
        products?.length &&
        taxon
      ) {
        const R3_CATEGORY = new window.r3_category()
        R3_CATEGORY.setId(taxon.id)

        products.forEach(function (prod) {
          R3_COMMON.addItemId(prod.id)
        })
      } else if (firstPlacementName.match('search_page') && products?.length) {
        const R3_SEARCH = new window.r3_search()
        if (this.$route.query.q) R3_SEARCH.setTerms(this.$route.query.q)

        products.forEach((p) => {
          R3_SEARCH.addItemId(p?.id)
        })
      } else if (firstPlacementName.match('cart_page') && lineItems?.length) {
        const R3_CART = new window.r3_cart()

        lineItems.forEach((lineItem) => {
          R3_CART.addItemId(lineItem.product_id, lineItem.sku)
        })
      } else if (firstPlacementName.match('item_page') && product) {
        const R3_ITEM = new window.r3_item()
        R3_ITEM.setId(product?.id)
        R3_ITEM.setName(product.name)
      }
    },
    addToCartRichTracker(product) {
      const R3_ADDTOCART = new window.r3_addtocart()

      R3_ADDTOCART.addItemIdToCart(product.id, product.sku)
    },
    purchaseRichTracker(order) {
      const R3_PURCHASED = new window.r3_purchased()
      R3_PURCHASED.setOrderNumber(order.number)

      order.lineItems.forEach(function (item) {
        R3_PURCHASED.addItemIdPriceQuantity(
          item.productId,
          item.price,
          item.quantity,
          item.sku
        )
      })
    },
    async loadRichScript() {
      if (!window.R3_COMMON) {
        await loadScript(
          window.location.protocol +
            '//media.richrelevance.com/rrserver/js/1.2/p13n.js'
        )
      }
    },
  },
}
