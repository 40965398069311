/**
 * Load an JavaScript script tag with given src
 *
 * @param {String} src script scr
 * @param {Boolean} options.once if true do not load script again if already loaded
 *
 * @returns {Promise} resolves after script load or fail
 */
export default function (src, options = { once: true }) {
  return new Promise((resolve, reject) => {
    const mountEl = options.parent || document.head || document.body || document

    const oldScript = document.querySelector(`script[src="${src}"]`)

    // Check if the script was already loaded
    if (oldScript && options?.once) {
      resolve('Script loaded')
    } else {
      oldScript?.remove()

      const s = document.createElement('script')

      s.setAttribute('src', src)

      // Set extra attributes
      if (options?.attrs) {
        Object.entries(options.attrs).forEach(([attr, value]) =>
          s.setAttribute(attr, value)
        )
      }

      s.onload = resolve
      s.onerror = reject

      mountEl.appendChild(s)
    }
  })
}
