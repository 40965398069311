/**
 * Call handler when el intersect document viewport
 *
 * @param {String | HTMLElement} el element to observe intersection
 * @param {Function} handler function called when el is intersected
 * @param {Object} options hanler and obserber options
 *
 * @returns {Function} Function to call for start observing (or call handler if observer is not supported)
 */

export default function createIntersectionHandler(
  el,
  handler,
  options = {
    once: true,
    observer: {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    },
  }
) {
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        // Stop observir the target if once options is true (default: true)
        options.once && observer.unobserve(el)

        handler()
      }
    })
  }

  return () => {
    if ('IntersectionObserver' in window) {
      const _el = typeof el === 'string' ? document.querySelector(el) : el
      const observer = new IntersectionObserver(
        handleIntersection,
        options.observer
      )

      observer.observe(_el)
    } else {
      handler()
    }
  }
}
